import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import './carousel.css';

export const Carousel = ({ images, dotColor, controlColor, borderColor, backgroundColor, containerColor }) => {

  const [currentImgIndex, setCurrentImgIndex] = useState(0);
  const intervalRef = useRef();

  const prevImage = () => {
      setCurrentImgIndex(currentImgIndex === 0 ? images.length - 1 : currentImgIndex - 1);
  }

  const nextImage = useCallback(() => {
      setCurrentImgIndex(currentImgIndex === images.length - 1 ? 0 : currentImgIndex + 1);
  }, [currentImgIndex, images.length])

  useEffect(() => {
      intervalRef.current = setInterval(() => {
          nextImage();
      }, 2000);

      return () => {
          clearInterval(intervalRef.current);
      }
  }, [currentImgIndex, nextImage]);

  const handleIndicatorClick = (i) => {
      setCurrentImgIndex(i);
  }

  return (
      <div className='carousel-container' style={{borderColor, backgroundColor: containerColor }}>
        <div className="carousel-images-container" style={{backgroundColor}}>
          <img 
          alt=""
              src={images[currentImgIndex]} 
              className="carousel-image"
          />
           <div className="carousel-indicator">
              {images.map((_, i) => 
                  <div 
                      style={{backgroundColor: dotColor}}
                      onClick={() => handleIndicatorClick(i)}
                      className={`carousel-indicator-dot ${i === currentImgIndex ? "active" : ""}`}
                  />
              )}
          </div>
        </div>
          <div className="controls-container">
            <button 
             style={{backgroundColor: controlColor}}
                className="carousel-button carousel-button-left"
                onClick={prevImage}
            >
                <IoIosArrowBack/>
            </button>
            <button
                style={{backgroundColor: controlColor}}
                className="carousel-button carousel-button-right"
                onClick={nextImage}
            >
                <IoIosArrowForward/>
            </button>
          </div>
          
         
      </div>
  );
}
