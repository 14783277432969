import React, { useState } from "react";
import "./giftreveal.css";  // assuming you have the related CSS file for styling
import presentTop from './present_top.png';
import presentBottom from './present_bottom.png';
import coupons from './coupons.png';
import plant from './plant.png';
import gojo from './gojo.png';

export const GiftReveal = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
  }

  return (
    <div className="giftContainer">
      <div className={`presentImagesContainer ${isClicked ? 'fade-out' : ''}`}>
        <img id="presentTop" src={presentTop} alt="" onClick={handleClick}/>
        <img src={presentBottom} alt="" />
        <div id="label" className="presentLabel">
          Click to reveal
        </div>
      </div>

       <div className={`revealedItem ${isClicked ? 'fade-in' : ''}`}>
          <img src={coupons} alt=""/>
          <div style={{padding: 24, textAlign: 'center'}}>
            Use them wisely!
            <p style={{fontSize: 3}}>
              Terms and conditions: Chloe has full discretion to reject proposals that she deems unappropriate 😡
            </p>
          </div>
          <h1 style={{textAlign: 'center', paddingBottom: 24}}>Also coming asap when she has more time</h1>
            <div style={{display: 'flex', flexDirection: 'row', gap: 24, alignItems: 'center', justifyContent: 'center'}}>
              <Card
                title="Crochet hanging plant"
                imgSrc={plant}
                border="3px dashed #90de3e"
              />
              <Card
                title="Crochet Gojo Plushie"
                imgSrc={gojo}
                border="3px dashed #6767cf"
              />
          
            </div>
          </div>
        
    </div>
  );
};

const Card = ({title, imgSrc, border}) => (
  <div style={{display: 'flex', flexDirection: 'column', gap: 24, alignItems: 'center', justifyContent: 'center', padding: 24, border}}>
    <img width={300} src={imgSrc} alt=""/>
    <h3>{title}</h3>
  </div>
);
