import React, { Component } from 'react'
import Client from '../Contentful'
import SectionTitle from '../components/SectionTitle'
import CardModal from '../components/CardModal'
import ImageCarousel from '../components/ImageCarousel';
import Loading from '../components/Loading'
import {FaGithub} from 'react-icons/fa'
import {RiDeleteBinLine, RiComputerLine, RiUserLine} from "react-icons/ri";
import titleImg from '../images/icons/project.svg';
export class Projects extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            projects: [],
            loading: true,
            modalContent: null,
            showModal: false,
        }
    }
    getData = async() => {
        try {
            let res = await Client.getEntries({
                content_type: 'projectEntry',
                order: '-fields.year'
            });
            this.setState({
                projects: res.items,
                loading: false,
            })
        } catch(err) {
            console.log(err);
        }
    
    }
    componentDidMount() {
        this.getData();
    }
    setModalContent(project) {
        this.setState({
            modalContent: project
        }, () => {
            this.toggleModal()
        })
        
    }
    toggleModal() {
        this.props.toggleModal();
        this.renderModal();
    }
    projectsList(projects) {
        return projects.map((project, index) => {
            let {name, 
                year,
                images
            } = project.fields;
            let image = images[0].fields.file.url;
            return (
                <div key={index} onClick={() => {this.setModalContent(project)}} className="card-container card-grid-elem">
                    <img src={image} alt={`${name} preview screenshot`}/>
                    <div className="card-footer">
                        <h2>{name}</h2>
                        <div className="align-left">
                            <h2>{year}</h2>
                        </div>
                    </div>
                </div>
            )
        })
    }
    renderModal = () => {
        let {name, 
            year,
            description,
            images,
            links,
        } = this.state.modalContent.fields;
        return this.props.setModal(
        <CardModal toggleModal={this.props.toggleModal}>
            <ImageCarousel name={name} images={images}/>
            <div className="modal-side-wrap">
            <div className="modal-side">
            <article className="card-container">
                <div className="card-body center" >
                <div className="card-footer">
                    <div className="title center">
                        <h2>{name}</h2>
                        <h3>{year}</h3>
                    </div>
                    
                </div>
                    {description}
                </div>
                <div className="card-footer cards-grid">
                    {
                        links.map((link, i) => {
                            let linkIcon;
                            switch(link.type.toLowerCase()) {
                                case 'github' :
                                    linkIcon = <FaGithub/>
                                    break;
                                case 'bin':
                                    linkIcon = <RiDeleteBinLine/>
                                    break;
                                case 'devpost':
                                    linkIcon= <RiUserLine/>
                                    break;
                                default :
                                    linkIcon = <RiComputerLine/>
                                    break;
                            } 
                            return <a className="ctaBtn card-link" key={i} rel="noopener noreferrer" target="_blank" href={link.url}>{linkIcon} {link.title}</a>
                        })
                    }
                </div>
            </article>
            </div>
            </div>
        </CardModal>);
    }
    render() {
        let {loading, projects} = this.state;
        if(loading === true) {
            return (
                <section id="projects" className="section-wrap">
                    <Loading/>
                </section>
                
            )
        }
        return (
            <>
            <section id="projects" className="section-wrap">
                <SectionTitle img={titleImg} alt="Rocket icon"><h1>Projects</h1></SectionTitle>
                <div className="cards-grid">
                    {this.projectsList(projects)}
                </div>
                
            </section>
            </>
        )
    }
}

export default Projects
