import React from 'react'
import PropTypes from 'prop-types';
export default function SectionTitle({children, img, alt}) {
    return (
        <div className="section-title">
            <img src={img} alt={alt}/> {children}
        </div>
    )
}

SectionTitle.propTypes = {
    children: PropTypes.object.isRequired,
    img: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
}