import React, { useState } from 'react';
import { SecretPageUnlocked } from '../components/secret/SecretPageSection';
import cakeImg from '../images/cake.png';

export const Secret = () => {
    const [password, setPassword] = useState("");
    const [access, setAccess] = useState(false);
    const [isActive, setActive] = useState(false);

    const correctPassword = process.env.REACT_APP_SECRET_PASSWORD; // Store your password in .env file
 
    const handlePassword = (event) => {
       if(event.target.value === correctPassword) {
           setAccess(true);
       }
       setPassword(event.target.value);
    }
 
    return (
      <div>
           {!access ? (
            <div className={`pageContainer ${isActive ? 'active' : ''}`}>
               <div className={`inputContainer ${isActive ? 'active' : ''}`}>
                <img alt="" style={{width: 250, paddingBottom: 28}} src={cakeImg}/>
                 <h1>
                  <label htmlFor="password">This page is only for someone special</label>
                  <div style={{marginTop: -38}} className='hidden'>Is it you? 🥺</div>
                  </h1>
                 <input onFocus={() => setActive(true)}
        onBlur={() => setActive(false)} type="password" id="password" value={password} onChange={handlePassword} />
               </div>
               </div>
           ) : (
               <SecretPageUnlocked/>
           )}
       </div>
    )
}
 