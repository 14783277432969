import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {IoIosMore} from "react-icons/io";
const links = [
    <Link to="/">Home</Link>,
    <Link to="/resume">Resume</Link>,
    <a href="https://github.com/chloesli" rel="noopener noreferrer" target="_blank">Github</a>,
    <a href="https://www.linkedin.com/in/chloesli/" rel="noopener noreferrer" target="_blank">LinkedIn</a>
]

export class Navbar extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            showNav: false
        }
    }
    
    linksMapped = () => {
        return (
            <ul>{
                links.map((link, index) => {
                    return <li className={"underline"} key={index}>{link}</li>
                })
                }
            </ul>
            
        )
    }

    toggleSideNav = () => {
        this.setState({
            showNav: !this.state.showNav, 
        })
    }

    render() {
        return (
            <>
                <nav className="navbar">
                    <Link to="/"> <h1>Chloe L.</h1></Link>
                    
                    <div className="links align-left">
                        {this.linksMapped()}
                    </div>
                    <div onClick={this.toggleSideNav} className={this.state.showNav ? "side-nav align-left show" : "side-nav align-left"}>
                        <div  className={this.state.showNav ? "nav-icon show" : "nav-icon"}>
                            <IoIosMore/>
                        </div>
                        <div className={this.state.showNav ? `nav-drawer show` : `nav-drawer`}>
                        {   this.linksMapped()}
                        </div>
                    </div>
                </nav>
                
            </>
        )
    }
}

export default Navbar
