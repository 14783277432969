import React from 'react';
import Home from './pages/Home'
import About from './pages/About'
import Resume from './pages/Resume'
import Navbar from './components/Navbar'
import Error from './pages/Error';
import { Secret } from './pages/Secret';
import './App.css';

import {Routes, Route} from "react-router-dom";

function App() {
  return (
    <>
      {window.location.pathname !== '/secret' && <Navbar/>}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/resume" element={<Resume />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<Error />} />
        <Route path="/secret" element={<Secret/>}/>
      </Routes>
    </>
  );
}

export default App;
