import React, { Component } from 'react'
import Client from '../Contentful'
import SectionTitle from '../components/SectionTitle'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Loading from '../components/Loading'

import titleImg from '../images/icons/girl.svg';
export class AboutMe extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            loading: true,
            content: {},
        }
    }
    
    getData = async() => {
        try {
            let res = await Client.getEntries({
                content_type: 'about'
            });
            this.setState({
                title: res.items[0].fields.title,
                introduction: res.items[0].fields.introduction,
                loading: false,
            })
        } catch(err) {
            console.log(err);
        }
    
    }
    componentDidMount() {
        this.getData();
    }
    renderIntroduction() {
        return documentToHtmlString(this.state.introduction);
    }
    render() {
        let {loading} = this.state;
        if(loading === true) {
            return (
                <section id="about" className="section-wrap center">
                    <Loading/>
                </section>
                
            )
        }
    
        return (
            <section id="about" className="section-wrap center">
                <SectionTitle img={titleImg} alt="Girl icon"><h1>{this.state.title}</h1></SectionTitle>
                <div className="card-body bg-transparent" dangerouslySetInnerHTML={{__html: this.renderIntroduction()}}>
                
                </div>
            </section>
        )
    }
}

export default AboutMe
