import React from 'react'

function Loading() {
    return (
        <div className="loading">
            <ul>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
    )
}

export default Loading
