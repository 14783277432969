import React, { Component } from 'react'
import {IoMdClose} from 'react-icons/io'

export class CardModal extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }
    toggleModal() {
        this.props.toggleModal();
    }
    render() {
        return (
            <div className="modal-container">

                <div className="modal-body">
                <div className="modal-close" onClick={() => this.toggleModal()}><IoMdClose/></div>
                    {this.props.children}
                </div>
                <div className="modal-background" onClick={() => this.toggleModal()}></div>
                
            </div>
        )
    }
}

export default CardModal
