import React, { Component } from 'react'
import Client from '../Contentful'
import SectionTitle from '../components/SectionTitle'
import Loading from '../components/Loading'

import titleImg from '../images/icons/help.svg';

export class ExtraCurricular extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            extraCurr: [],
            loading: true
        }
    }
    getData = async() => {
        let res = await Client.getEntries({
            content_type: 'extraCurricular',
            order: '-fields.name'
        });
        this.setState({
            extraCurricular: res.items,
            loading: false,
        })
    }
    componentDidMount() {
        this.getData();
    }

    extraList(extraCurricular) {
        return extraCurricular.map((project, index) => {
            let {name, description} = project.fields;
            return (
                <div key={index} className="card-container card-grid-elem">
                    <div className="card-heading">
                        <h2>{name}</h2>
                    </div>
                    <div className="card-body" dangerouslySetInnerHTML={{__html: description}}>
                        
                    </div>
                </div>
            )
            
               
            
     
        })
    }

    render() {
        let {loading, extraCurricular} = this.state;
        if (loading) {
            return (
                <section id="extracurricular" className="section-wrap">
                    <Loading/>
                </section>
                
            );
        }
        return (
            <section id="extracurricular" className="section-wrap">
                <SectionTitle img={titleImg} alt="Rocket icon"><h1>Extra Curricular</h1></SectionTitle>
                <div className="cards-grid">
                    {this.extraList(extraCurricular)}
                </div>    
            </section>
               
        
        )
    }
}

export default ExtraCurricular
