import React, { Component } from 'react'

export class ImageCarousel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeIndex: 1
        }
    }
    setActiveImage(index) {
        this.setState({
            activeIndex: index
        })
    }

    thumbnails(name) {
        let images = this.props.images.map((image, index) => {
            return <img key={index}  
                        className={this.state.activeIndex === index ? `active` : ``}
                        src={image.fields.file.url} 
                        onClick={() => this.setActiveImage(index)}
                        alt={`${name} preview ${index}`}
                    />
        })
        return <div className="thumbnail-container">{images}</div>;
    }
    render() {
        let {name, images} = this.props;

        return (
            <div className="img-carousel-container">
                <div className="active-img">
                    <img src={images[this.state.activeIndex].fields.file.url} alt={`${name} preview screenshot`}/>
                </div>
                {this.thumbnails(name)}
            </div>
                
        )
    }
}

export default ImageCarousel
