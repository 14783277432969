import React from 'react';
import Confetti from 'react-confetti';
import amalfi from './images/amalfi.jpg';
import img1 from './images/1.jpg';
import img2 from './images/2.jpg';
import img3 from './images/3.jpg';
import img4 from './images/4.jpg';
import img5 from './images/5.jpg';
import img6 from './images/6.jpg';
import img7 from './images/7.jpg';
import img8 from './images/8.jpg';
import img9 from './images/9.png';
import img10 from './images/10.jpg';
import car1 from './images/car2/1.jpg';
import car2 from './images/car2/2.jpg';
import car3 from './images/car2/3.jpg';
import car4 from './images/car2/4.jpg';
import car5 from './images/car2/5.jpg';
import car6 from './images/car2/6.jpg';
import car7 from './images/car2/7.jpg';
import car8 from './images/car2/8.jpg';
import car9 from './images/car2/9.jpg';
import car10 from './images/car2/10.jpg';
import car11 from './images/car2/11.jpg';
import car12 from './images/car2/12.jpg';
import car13 from './images/car2/13.jpg';
import car14 from './images/car2/14.jpg';
import car15 from './images/car2/15.jpg';
import car16 from './images/car2/16.jpg';
import car17 from './images/car2/17.jpg';
import car18 from './images/car2/18.jpg';
import car19 from './images/car2/19.jpg';
import car20 from './images/car2/20.jpg';
import car22 from './images/car2/22.jpg';

import { Carousel } from './ui/carousel'
import {GiftReveal} from './ui/GiftReveal';

export const SecretPageUnlocked = () => {
  return (
    <div style={{overflow: 'hidden', position: 'relative'}}>
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
      />
      <div class="confettiContainer" style={{background: 'linear-gradient(114deg, #C39E9B 0.13%, #ABA6E5 0.14%, #FF836C 30.29%, #FFC55B 78.36%, #FEFC43 94.38%)'}}>
        <div style={{display: 'flex', flexDirection:'column', padding: 24, maxWidth: 650, gap: 38,  color: 'white'}}>
          <h1 style={{fontWeight: 'bold',  color: 'white'}}>HAPPY BIRTHDAY TO MY FAVOURITE PERSON IN THE WHOLE WORLD</h1>
          <p>
            Amore mio, I'm forever grateful that you crash landed into my life 2 years ago! Since that day, we naturally
            became the bestest of friends and ended up travelling around Europe together where I realised how much I loved spending time with you everyday,
            and I never wanted our time together to end.
          </p>
          <p>
            Even though we're thousands of kilometers away, I always have you in my heart and mind and i'm waiting patiently for the day we can be reunited again and spend every the rest of our lives together.
          </p>
          <p>
            You make me so happy cat.
          </p>
          <p>Love you, and I wish you a birthday as happy as it can be without me by your side.</p>
        </div>
        <img alt="" src={amalfi}/>
      </div>
      <div style={{backgroundColor: "#FF5D5D", minHeight:400, position: 'relative'}}>
        <CrimeSection/>
      </div>
      <div style={{backgroundColor: "#fae1fe", minHeight:400, position: 'relative'}}>
        <CareSection/>
      </div>
      <div style={{padding: 100, backgroundColor: "white", minHeight:400, position: 'relative'}}>
        
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 48}}>
          <h1>From me, to you</h1>
          <GiftReveal/>
          <Confetti
        width={window.innerWidth}
        height={800}
      />
        </div>
      </div>
    </div>
  )
}
const CrimeSection = () => {
  return (
    <div class="twoColContainer" style={{padding: 38}}>
    <div style={{display: 'flex', flexDirection:'column', padding: 24, gap: 38, color: 'white'}}>
    <h1 style={{fontWeight: 'bold',  color: 'white'}}>My partner in crime, 😈</h1>
     <p>
    We've had soo many fun adventures together because every day with you is like a fun adventure. I can't wait to make more memories with you and travel to new places together. We've had soo much fun on our travels and you
    never fail to make me laugh and smile.
      
      </p>Even though things can go wrong like when we missed the bus at Mont Sant Michel, when our motorbikes broke down in Vietnam,
    or when we missed the last train to sorrento, we always find a way to get through it. Doing long distance is nothing for us. 💪
    <p>

    I'm so excited for all the memories we'll make together next year and all the crimes we'll commit together (for legal reasons it's a joke and hypothetical).
    </p>
  </div>
  <div>
    <FireConfetti/>
    <Carousel 
      dotColor='#e66666'
      controlColor='#ff9999'
      borderColor='#fff2f2'
      images={[
          img1,
          img4,
          img7,
          img8,
          img2,
          img6,
          img10,
          img3,
          img5,
          img9,
        ]}/>
    </div>
  </div>
  )
}
const CareSection = () => {
  return (
    <div class="twoColContainer" style={{padding: 38}}>
      <div>
      <Carousel 
      dotColor={'#6dc4fd'}
      controlColor={'#a9d5ff'}
      borderColor={'#87ceff'}
      backgroundColor={'#e4e7ff'}
      containerColor={'white'}
        images={[
          car1, car2, car3, car4, car5, car6, car7, car8, car9, car10, car11, car12, car13, car14, car15, car16, car17, car18, car19, car20, car22
      ]}/>
    </div>
      <div style={{display: 'flex', flexDirection:'column', padding: 24, gap: 38}}>
      <h1 style={{fontWeight: 'bold', color: '#e76bff'}}>My kind and caring daddy ❤️</h1>
     <p>
      I appreciate all the times you gave me the precious last bites of food, all the times you cooked for me, and looked after me and looked out for me.
      </p>
 
      You are so kind and considerate, an amazing listener and you always make me feel heard. You always cheer me on, inspire me to be better, and comfort me when i'm upset. I appreciate you for being so kind, gentle and patient with me.
      You always make me feel so safe and comfy, being with you feels like the first time you've slept in your own bed after months of being away from home.

      <p>
        I miss being in your arms, cuddling you and falling asleep with you by my side. <br></br>
      But I also miss giving you massages, squeezing your back pimples and cleaning your ears.

      </p>
      I can't wait to be in your presence again 🥰
      <p>Love from the luckiest girl in the world because thats how you make me feel 🥰</p>
      </div>
    </div>
  )
}
export default function FireConfetti() {
  const drawFlame = (ctx) => {
    ctx.beginPath();
    ctx.moveTo(-7, 10); // start at the bottom left of the flame
    ctx.bezierCurveTo(-9, -2, -5, -6, -2, -12);  // create the small left peak
    ctx.bezierCurveTo(0, -7, 5, -7, 5, -17); // create the large middle peak
    ctx.bezierCurveTo(7, -7, 15, 2, 10, 10); // create the medium right peak and come back to the bottom
    ctx.bezierCurveTo(5, 15, -5, 15, -7, 10); // round the bottom of the flame
    ctx.closePath();
    ctx.fill();
  };
  return (
    <Confetti
      drawShape={drawFlame}
      width={window.innerWidth}
      height={600}
      numberOfPieces={40}
      colors={['#ff0000', '#ff7300', '#fffa00']}
    />
  );
}
