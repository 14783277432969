import React, { Component } from 'react'
import Client from '../Contentful'
import SectionTitle from '../components/SectionTitle'
import Loading from '../components/Loading'
import titleImg from '../images/icons/resume.svg';
export class Resume extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            loading: true,
            
        }
    }
    
    getData = async() => {
        try {
            let res = await Client.getEntries({
                content_type: 'about'
            });
            this.setState({
                resumePdf: res.items[0].fields.resumePdf.fields.file.url,
                resumeImg:res.items[0].fields.resume.fields.file.url,
                loading: false,
            })
        } catch(err) {
            console.log(err);
        }
    
    }
    componentDidMount() {
        this.getData();
    }

    render() {
        let {loading} = this.state;
        if(loading === true) {
            return (
                <section id="resume" className="section-wrap center">
                    <Loading/>
                </section>
                
            )
        }
        return (
            <section id="resume" className="section-wrap center">
                <SectionTitle img={titleImg} alt="Girl icon"><h1>Resume</h1></SectionTitle> 
                    <a className="ctaBtn" href={this.state.resumePdf} rel="noopener noreferrer" target="_blank">Click for PDF Version</a>
                   <img id="resumeImg" alt="resume" src={this.state.resumeImg}/>
            </section>
        )
    }
}

export default Resume
