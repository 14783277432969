import React, { Component } from 'react'
import Projects from '../components/Projects'
import ExtraCurricular from '../components/ExtraCurricular'
import About from '../components/AboutMe'
export class Home extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            loading: true,
            showModal: false,
            modal: null,
        }
    }
    toggleModal() {
        this.setState({
            showModal: !this.state.showModal
        })
    }
    setModal(modal) {
        this.setState({
            modal
        })
    }
    render() {
        return (
            <>
            <About/>
            <Projects toggleModal={this.toggleModal.bind(this)} setModal={this.setModal.bind(this)}/>
            <ExtraCurricular/>
            {this.state.showModal ? this.state.modal : null}
            </>
        )
    }
}

export default Home
